import React, { useState, useEffect, useRef, createContext, useContext } from 'react';
import dataProvider from '../../lib/dataProvider';
import { apiPath } from '../../lib/isTest';
import { CheckRecht, getInfoValue, getGlobalData } from '../../lib/globalData';
import { useTranslate } from 'react-admin';

import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import 'moment/dist/locale/de';

const ParamContext = createContext();

const ParamProvider = ({ children }) => {
  const [params, setParams] = useState('');
  const [data, setData] = useState({id_lang: getInfoValue('id_sprache'), id_report: 0, startDate: moment().startOf('month').format('YYYY-MM-DD'), endDate: moment().endOf('month').format('YYYY-MM-DD'), params: [], reports: []});

  return (
    <ParamContext.Provider value={{ params, setParams, data, setData }}>
      {children}
    </ParamContext.Provider>
  );
};

const UrlField = ({ title, type }) => {
  const { params, data } = useContext(ParamContext)
  const formEl = useRef(null);
  const token = getGlobalData('token');
  const doReport = (ev) => {
    formEl.current.submit();
  }
  return (
    <>
      <Button onClick={doReport} variant="contained" sx={{ textDecoration: 'none', width: '14em' }}>
        {title}
      </Button>
      <form method="post" action={apiPath+"v1/repReport/gen"} ref={formEl} target="_blank">
        <input type="hidden" name="token" value={token} />
        <input type="hidden" name="type" value={type} />
        <input type="hidden" name="id_lang" value={data.id_lang} />
        <input type="hidden" name="id_report" value={data.id_report} />
        <input type="hidden" name="startDate" value={data.startDate} />
        <input type="hidden" name="endDate" value={data.endDate} />
        <input type="hidden" name="params" value={params} />
        <input type="hidden" name="id" value={0} />
      </form>
    </>
  )
}

const sortByName = (data) => {
  return Object.entries(data).sort((a, b) => {
    if (a[1] < b[1]) return -1;
    if (a[1] > b[1]) return 1;
    return 0;
  });
}

const sortByKey = (data) => {
  return Object.entries(data).sort((a, b) => {
    if (Number(a[0]) < Number(b[0])) return -1;
    if (Number(a[0]) > Number(b[0])) return 1;
    return 0;
  });
}

const readParamsArray = (values, choices) => {
  var ret = {}
  if(typeof values === 'string'){
    const arr = values.split(',')
    for(const key in choices){
      ret[key] = (arr.indexOf(key) !== -1)
    }
  }
  else return values
  return ret
}

const MultiInput = ({ params, updateData }) => {
  const { setParams, data } = useContext(ParamContext)
  const [value, setValue] = useState((data.params.values && typeof data.params.values[params.name] !== 'undefined')?data.params.values[params.name]:params?.default);
  const [value2, setValue2] = useState((data.params.values && typeof data.params.values[params.name2] !== 'undefined')?data.params.values[params?.name2]:params?.default2);
  const [valueBool, setValueBool] = useState((data.params.values && (typeof data.params.values[params.name] !== 'undefined'))?(data.params.values[params.name]==1?true:false):params?.default);
  const [valueArr, setValueArr] = useState((data.params.values && data.params.values[params.name])?readParamsArray(data.params.values[params.name], params.choices):{});
  
  const updateParamValue = (key, value) => {
    const p = {...data.params}
    p.values[key] = value
    updateData({...data, params: p })
    setParams( JSON.stringify(p.values) )
  }
  
  if(!params.name)return null;
  var field1 = params.text;
  var field2 = '';
  var field3 = '';
  var field4 = '';
  var field5 = '';
  var width = [2,3,3,2,2]
  var cstyle = {width:'100%'};
  var gstyle = [{alignContent:'center'},{alignContent:'center'},{alignContent:'center'},{alignContent:'center'},{alignContent:'center'}];
  const choices = []
  var SChoices = {}
  if(params.width)width = params.width
  switch(params.type) {
    case 'title':
      field1 = <b style={{marginBottom: "1em"}}>{field1}</b>;
      cstyle = {...cstyle, ...{height:'2em'}};
      break;
    case 'space':
      field1 = <div> </div>;
      cstyle = {...cstyle, ...{height:'2em'}};
      break;
    case 'line':
      field1 = <hr />;
      cstyle = {...cstyle, ...{height:'2em'}};
      break;
    case 'value':
      field2 = value
      cstyle = {...cstyle, ...{marginTop:'1em'}};
      break;
    case 'link':
      field1 = <a href={params.default} target="_blank">{params.text}</a>
      cstyle = {...cstyle, ...{marginTop:'1em'}};
      break;
    case 'text':
    case 'number':
      field2 = <TextField label={false} value={value} size="small" variant="outlined" sx={{ minWidth: '6em'}} onChange={(ev)=>{setValue(ev.target.value);updateParamValue(params.name, ev.target.value)}} />;
      if(params.text2)field3 = params.text2
      break;
    case 'time':
      width = [2,1,2,3,4]
      field2 = <TextField label={false} value={value} size="small" variant="outlined" sx={{ minWidth: '6em', width: '95%'}} onChange={(ev)=>{setValue(ev.target.value);updateParamValue(params.name, ev.target.value)}} />;
      if(params.text2)field3 = params.text2
      break;
    case 'textDouble':
      field2 = <TextField label={false} value={value} size="small" variant="outlined" sx={{ minWidth: '6em'}} onChange={(ev)=>{setValue(ev.target.value);updateParamValue(params.name, ev.target.value)}} />
      if(params.text2)field3 = params.text2
      field4 = <TextField label={false} value={value2} size="small" variant="outlined" sx={{ minWidth: '6em'}} onChange={(ev)=>{setValue2(ev.target.value);updateParamValue(params.name2, ev.target.value)}} />
      if(params.text3)field5 = params.text3
      break;
    case 'checkbox':
      field2 = <Checkbox label={false} checked={valueBool} onChange={(ev)=>{updateParamValue(params.name, ev.target.checked===true?'1':'0');setValueBool(ev.target.checked===true?true:false)}} />;
      if(params.text2)field3 = params.text2
      break;
    case 'select':
      SChoices = sortByName(params.choices)
      for(const key in SChoices){
        choices.push(<MenuItem value={SChoices[key][0]}>{SChoices[key][1]}</MenuItem>)
      }
      field2 = <Select size="small" sx={{ minWidth: '6em', width: '100%'}} label={false} value={value} onChange={(ev)=>{setValue(ev.target.value);updateParamValue(params.name, ev.target.value)}} >{choices}</Select>;
      if(params.text2)field3 = params.text2
      break;
    case 'selectDouble':
      const choices2 = []
      width = [2,2,1,3,4]
      if(params.ksort){
        SChoices = sortByKey(params.choices)
      }
      else {
        SChoices = sortByName(params.choices)
      }
      for(const key in SChoices){
        choices.push(<MenuItem value={SChoices[key][0]}>{SChoices[key][1]}</MenuItem>)
      }
      if(params.ksort){
        SChoices = sortByKey(params.choices2)
      }
      else {
        SChoices = sortByName(params.choices2)
      }
      for(const key in SChoices){
        choices2.push(<MenuItem value={SChoices[key][0]}>{SChoices[key][1]}</MenuItem>)
      }
      field2 = <Select size="small" sx={{minWidth: '8em', width: '100%'}} label={false} value={value} onChange={(ev)=>{setValue(ev.target.value);updateParamValue(params.name, ev.target.value)}} >{choices}</Select>;
      field3 = <Select size="small" sx={{minWidth: '8em'}} label={false} value={value2} onChange={(ev)=>{setValue2(ev.target.value);updateParamValue(params.name2, ev.target.value)}} >{choices2}</Select>;
      break;
    case 'multiSelect':
      for(const key in params.choices){
        choices.push(
          <span>
            <Checkbox label={false} checked={valueArr[key]} onChange={(ev)=>{const varr = {...valueArr};varr[key] = ev.target.checked;updateParamValue(params.name, varr);console.log(varr);setValueArr(varr)}} />
            {params.choices[key]}
          </span>
        )
        field2 = <div style={{display:'grid'}}>{choices}</div>
      }
      break;
    default:
      break;
  }
  if(!field2 && params.type !== 'title' && params.type !== 'space' && params.type !== 'line' && params.type !== 'value' && params.type !== 'link')return null;
  if(params.style){
    for(const key in params.style){
      if(typeof params.style[key] === 'object'){
        for(const skey in params.style[key]){
          gstyle[key][skey] = params.style[key][skey]
        }
      }
    }
  }
  return (
    <Grid container style={cstyle}>
      <Grid item xs={12} md={width[0]} sx={gstyle[0]}>{field1}</Grid>
      <Grid item xs={12} md={width[1]} sx={gstyle[1]}>{field2}</Grid>
      <Grid item xs={12} md={width[2]} sx={gstyle[2]}>{field3}</Grid>
      <Grid item xs={12} md={width[3]} sx={gstyle[3]}>{field4}</Grid>
      <Grid item xs={12} md={width[4]} sx={gstyle[4]}>{field5}</Grid>
    </Grid>
  )
}

const ReportForm = () => {
  const tr = useTranslate();
  const { setParams, data, setData } = useContext(ParamContext)
  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(moment().add(1, 'month'))
  const [report, setReport] = useState(0);
  const [showEndDate, setShowEndDate] = useState(true);

  moment.locale('de');

  const updateData = (data) => {
    if(data){
      setData(data)
      if(data.id_report){
        setReport(data.id_report)
      }
      setShowEndDate(data?.showEndDate?true:false)
      setParams( JSON.stringify(data.params.values) )
    }
  }

  useEffect(() => {
    dataProvider.update('repReport', {id:0, data: data })
    .then(res  => {
      updateData(res.data)
    })
  }, []);

  const reloadData = (updData) => {
    dataProvider.update('repReport', {id:0, data: {...data, ...updData} })
    .then(res  => {
      updateData(res.data)
    })
  }

  const storeData = (updData) => {
    updateData({...data, ...updData})
  }

  
  console.log(["rerender"])
  
  return (        
    <Paper style={{scroll:'auto'}} >
      <CardContent>
      <div style={{ marginTop: '1em'}}>
        <div style={{ marginLeft: '0.3em', fontSize: '0.9em', color: 'grey' }}>{tr('bw.rep.report.language')}</div>
        <Select value={data.id_lang} onChange={(ev) => {reloadData({ id_lang: ev.target.value} ) }} size="small" sx={{width: '15em'}} >
          <MenuItem value={1}>Deutsch</MenuItem>
          <MenuItem value={2}>English</MenuItem>
          <MenuItem value={3}>Français</MenuItem>
        </Select>
      </div>
      
      <div style={{ marginTop: '1em'}}>
        <div style={{ marginLeft: '0.3em', fontSize: '0.9em', color: 'grey' }}>{tr('bw.rep.report.reportType')}</div>
        <Select value={report} onChange={(ev) => {reloadData({ id_report: ev.target.value}) }} size="small" sx={{width: '20em'}}>
          { data.reports.map((el) => <MenuItem value={el.id}>{el.bez}</MenuItem>) }
        </Select>
      </div>
      
      <div style={{ marginTop: '1em'}}>
        { data.params.fields && data.params.fields.map((el) =><MultiInput params={el} updateData={updateData} />)}
      </div>

      <div style={{ marginTop: '1em', display:'flex', justifyContent: 'flex-start', paddingBottom: '1em'}}>
        <LocalizationProvider dateAdapter={AdapterMoment} >
          <div>
            <div style={{ marginLeft: '.3em', fontSize: '0.9em', color: 'grey' }}>{tr('bw.rep.report.startDate')}</div>
            <DatePicker label='' value={startDate}
              onChange={(ev) => {setStartDate(ev); storeData({ startDate: ev.format('YYYY-MM-DD')})} }
              sx={{mt: 0, mb: 0, width: '12.3em',
              '& .MuiInputBase-root': { height: '40px', padding: '0 14px' },
              '& .MuiFilledInput-input': { padding: '0 0px', fontSize: '1.2em' },
              '& .MuiInput-underline:before': { borderBottom: 'none' },
              '& .MuiInput-underline:after': { borderBottom: 'none' },
              '& .MuiInput-underline': { '&:hover:not(.Mui-disabled):before': { borderBottom: 'none' }}, }}
            />
          </div>
          { showEndDate && <div>
            <div style={{ marginLeft: '1.3em', fontSize: '0.9em', color: 'grey' }}>{tr('bw.rep.report.endDate')}</div>
            <DatePicker label='' value={endDate}
              onChange={(ev) => {setEndDate(ev); storeData({ endDate: ev.format('YYYY-MM-DD')})}}
              sx={{mt: 0, mb: 0, ml: '1em', width: '12.3em',
              '& .MuiInputBase-root': { height: '40px', padding: '0 14px' },
              '& .MuiFilledInput-input': { padding: '0 0px', fontSize: '1.2em' },
              '& .MuiInput-underline:before': { borderBottom: 'none' },
              '& .MuiInput-underline:after': { borderBottom: 'none' },
              '& .MuiInput-underline': { '&:hover:not(.Mui-disabled):before': { borderBottom: 'none' }}, }}
            />
          </div> }
        </LocalizationProvider>
      </div>
      
      <div style={{display:'flex', justifyContent: 'space-between', flexDirection: 'column', gap: '1em'}}>
        <div style={{display:'flex', justifyContent: 'flex-start', gap: '1em'}}>
          <Button variant="outlined" color="primary" value="-1d" 
            onClick={()=>{
              storeData({ 
                startDate: moment(startDate).subtract(1, 'day').format('YYYY-MM-DD'), 
                endDate: moment(endDate).subtract(1, 'day').format('YYYY-MM-DD'), 
              });
              setStartDate(moment(startDate).subtract(1, 'day'));
              setEndDate(moment(endDate).subtract(1, 'day'));
            }} 
            style={{width: '14em'}}>
            {tr('bw.rep.report.oneDayBack')}
          </Button>
          <Button variant="outlined" color="primary" value="today" 
            onClick={()=>{
              storeData({ 
                startDate: moment().format('YYYY-MM-DD'), 
                endDate: moment().format('YYYY-MM-DD'), 
              });
              setStartDate(moment());
              setEndDate(moment());
            }} 
            style={{width: '14em'}}>
            {tr('bw.rep.report.today')}
          </Button>
          <Button variant="outlined" color="primary" value="+1d" 
            onClick={()=>{
              storeData({ 
                startDate: moment(startDate).add(1, 'day').format('YYYY-MM-DD'), 
                endDate: moment(endDate).add(1, 'day').format('YYYY-MM-DD'), 
              });
              setStartDate(moment(startDate).add(1, 'day'));
              setEndDate(moment(endDate).add(1, 'day'));
            }} 
            style={{width: '14em'}}>
            {tr('bw.rep.report.addOneDay')}
          </Button>
        </div>

        <div style={{display:'flex', justifyContent: 'flex-start', gap: '1em'}}>
          <Button variant="outlined" color="primary" value="-1m" 
            onClick={()=>{
              storeData({ 
                startDate: moment(startDate).subtract(1, 'month').format('YYYY-MM-DD'), 
                endDate: moment(endDate).subtract(1, 'month').format('YYYY-MM-DD'), 
              });
              setStartDate(moment(startDate).subtract(1, 'month'));
              setEndDate(moment(endDate).subtract(1, 'month'));
            }} 
            style={{width: '14em'}}>
            {tr('bw.rep.report.oneMonthBack')}
          </Button>
          <Button variant="outlined" color="primary" value="thisMonth" 
            onClick={()=>{
              storeData({ 
                startDate: moment().startOf('month').format('YYYY-MM-DD'), 
                endDate: moment().endOf('month').format('YYYY-MM-DD'), 
              });
              setStartDate(moment().startOf('month'));
              setEndDate(moment().endOf('month'));
            }} 
            style={{width: '14em'}}>
            {tr('bw.rep.report.thisMonth')}
          </Button>
          <Button variant="outlined" color="primary" value="+1m" 
            onClick={()=>{
              storeData({ 
                startDate: moment(startDate).add(1, 'month').format('YYYY-MM-DD'), 
                endDate: moment(endDate).add(1, 'month').format('YYYY-MM-DD'), 
              });
              setStartDate(moment(startDate).add(1, 'month'));
              setEndDate(moment(endDate).add(1, 'month'));
            }} 
            style={{width: '14em'}}>
            {tr('bw.rep.report.addOneMonth')}
          </Button>
        </div>

        <div style={{display:'flex', justifyContent: 'flex-start', gap: '1em'}}>
          <Button variant="outlined" color="primary" value="-1y" 
            onClick={()=>{
              storeData({ 
                startDate: moment(startDate).subtract(1, 'year').format('YYYY-MM-DD'), 
                endDate: moment(endDate).subtract(1, 'year').format('YYYY-MM-DD'), 
              });
              setStartDate(moment(startDate).subtract(1, 'year'));
              setEndDate(moment(endDate).subtract(1, 'year'));
            }} 
            style={{width: '14em'}}>
            {tr('bw.rep.report.oneYearBack')}
          </Button>
          <Button variant="outlined" color="primary" value="thisYear" 
            onClick={()=>{
              storeData({ 
                startDate: moment().startOf('year').format('YYYY-MM-DD'), 
                endDate: moment().endOf('year').format('YYYY-MM-DD'), 
              });
              setStartDate(moment().startOf('year'));
              setEndDate(moment().endOf('year'));
            }} 
            style={{width: '14em'}}>
            {tr('bw.rep.report.thisYear')}
          </Button>
          <Button variant="outlined" color="primary" value="+1y" 
            onClick={()=>{
              storeData({ 
                startDate: moment(startDate).add(1, 'year').format('YYYY-MM-DD'), 
                endDate: moment(endDate).add(1, 'year').format('YYYY-MM-DD'), 
              });
              setStartDate(moment(startDate).add(1, 'year'));
              setEndDate(moment(endDate).add(1, 'year'));
            }} 
            style={{width: '14em'}}>
            {tr('bw.rep.report.addOneYear')}
          </Button>
        </div>
      </div>

      <div style={{marginTop: '2em', display:'flex', justifyContent: 'space-between', flexDirection: 'column', gap: '1em'}}>
        <div style={{display:'flex', justifyContent: 'flex-start', gap: '0.5em'}}>
          { !data.noPdf && <UrlField title='Create PDF' type='pdf' /> }
          { !data.noExcel && <UrlField title='Create Exel' type='xls' /> }
          { !data.noCsv && <UrlField title='Create CSV' type='csv' /> }
        </div>
        <div style={{display:'flex', justifyContent: 'flex-start', gap: '0.5em'}}>
          <CheckRecht route='repReport' level={9}>
            <UrlField title='SQL' type='sql' /> 
            <UrlField title='Json' type='json' />
            { !data.noLink && <UrlField title='Link' type='link' /> }
          </CheckRecht>
        </div>
      </div>
      </CardContent>
    </Paper>         
  )
}

const RepReport = () => (
  <ParamProvider>
    <ReportForm />
  </ParamProvider> 
)

export default RepReport;