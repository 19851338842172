import React, { useState, useEffect } from 'react';
import dataProvider from '../../lib/dataProvider';
import { isHauptadmin } from '../../lib/globalData';
import { useTranslate } from 'react-admin';

import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Calendar from '../../lib/react-calendar/Calendar.tsx';
import fixStyles from './styles.module.css'; // Ensure to have this in your project structure
import moment from 'moment';
import 'moment/dist/locale/de';


const CalCalendar = (props) => {
  const tr = useTranslate();
  const [startDate, setStartDate] = useState(moment().startOf('month'))
  const [endDate, setEndDate] = useState(moment().add(2, 'month'))
  const [dates, setDates] = useState({});
  const [data, setData] = useState({});
  const [error, setError] = useState(false);
  const [reload, setReload] = useState(0);
  const [menuItems, setMenuItems] = useState({preis: [], zimmer: [], online: [], zusatz: []});
  const [menuState, setMenuState] = useState({preis: 0, zimmer: 0, online: 0, zusatz: 0});
  const [weekdays, setWeekdays] = useState([true, true, true, true, true, true, true]);
  const [months, setMonths] = useState(3);
  const [rDaily, setRDaily] = useState(false);
  const [showRooms, setShowRooms] = useState(false);
  const [click, setClick] = useState([0,0]);
  const [range, setRange] = useState([0,0]);
  const [apiRange, setApiRange] = useState([tr('bw.cal.calendar.pleasechoose'), tr('bw.cal.calendar.pleasechoose')]);
  const [legend, setLegend] = useState({preis: {}, zimmer: {}, online: {}, zusatz: {}});
  const [undoData, setUndoData] = useState({});
  const [undoIdx, setUndoIdx] = useState(0);

  moment.locale('de');

  const getMenuItems = (items) => {
    const ret = []
    for(const ik in items){
      if(items[ik]?.dis === 0){
        ret.push(<MenuItem sx={{backgroundColor:items[ik]?.farbe}} value={items[ik]?.id}>{items[ik]?.bezeichnung}</MenuItem>)
      }
    }
    return ret
  }

  useEffect(() => {
    dataProvider.update('calCalendar', {id:0 })
    .then(res  => {
      const items = menuItems
      setData(res.data)
      if(res?.data?.preis){
        items.preis = getMenuItems(res.data.preis)
      }
      if(res?.data?.zimmer){
        const ms = menuState
        ms['zimmer'] = Object.entries(res.data.zimmer)[0][0]
        setMenuState(ms)
        items.zimmer = getMenuItems(res.data.zimmer)
      }
      if(res?.data?.online){
        items.online = getMenuItems(res.data.online)
      }
      if(res?.data?.zusatz){
        items.zusatz = getMenuItems(res.data.zusatz)
      }
      setMenuItems(items)
    })
  }, [reload]);

  useEffect(() => {
    dataProvider.update('calCalendar/getdates', {id:0, data:{startDate: startDate.format('YYYY-MM-DD'), months: months} })
    .then(res  => {
      setDates(res.data)
    })
  }, [startDate, months]);

  const assignDates = () => {
    const uData = undoData
    uData[undoIdx] = {startDate: startDate.format('YYYY-MM-DD'), months: months, weekdays: weekdays, range: apiRange, dates: dates}
    dataProvider.update('calCalendar/assigndates', {id:0, data:{startDate: startDate.format('YYYY-MM-DD'), months: months, types: menuState, weekdays: weekdays, range: apiRange} })
    .then(res  => {
      setUndoIdx(undoIdx+1)
      setUndoData(uData)
      setDates(res.data)
      if(res?.data?.error && res.data.error.length>0){
        setError(res.data.error)
      }
      if(res?.data?.reload){
        setReload(reload+1)
      }
      setMenuState({...menuState, ...{preis: 0, online: 0, zusatz: 0}})
      setLegend({preis: {}, zimmer: {}, online: {}, zusatz: {}})
    })
  }

  const undo = () => {
    dataProvider.update('calCalendar/undo', {id:0, data: undoData[undoIdx-1] })
    .then(res  => {
      console.log(res)
      setUndoIdx(undoIdx-1)
      setDates(res.data)
      if(res?.data?.reload){
        setReload(reload+1)
      }
      setLegend({preis: {}, zimmer: {}, online: {}, zusatz: {}})
    })
  }

  const handleSetRange = (val, ev) => {
    const r = [...range]
    const cl = [...click]
    cl[0] = cl[1]
    cl[1] = val[1]?val[1]:val[0]
    if(cl[0] === 0){
      r[0] = cl[1]
      r[1] = cl[1]
    }
    else if(cl[0] < cl[1]){
      r[0] = cl[0]
      r[1] = cl[1]
    }
    else {
      r[0] = cl[1]
      r[1] = cl[0]
    }
    setClick(cl)
    setRange(r)
    setApiRange([moment(r[0]).format('YYYY-MM-DD'), cl[0]===0?apiRange[1]:moment(r[1]).format('YYYY-MM-DD')])
  }

  const handleSetMonths = (range) => {
    const eD = moment(startDate).add((range-1), 'months')
    setMonths(range)
    setEndDate(eD)
    proccesDateChange(startDate, eD)
  }

  const handleSetStartDate = (sD) => {
    const eD = moment(sD).add((months-1), 'months')
    setEndDate(eD)
    setStartDate(sD)
    proccesDateChange(sD, eD)
  }

  const handleSetEndDate = (eD) => {
    setMonths(eD.diff(startDate, 'months')+1)
    setEndDate(eD)
    proccesDateChange(startDate, eD)
  }

  const handleShiftMonth = (shift) => {
    const sD = moment(startDate).add((shift), 'months')
    const eD = moment(endDate).add((shift), 'months')
    setStartDate(sD)
    setEndDate(eD)
    proccesDateChange(sD, eD)
  }

  const proccesDateChange = (sD, eD) => {
    const r = [...range]
    const cl = [...click]
    const startRange = moment(range[0])
    const endRange = moment(range[1])
    if(!startRange.isBetween(sD.startOf('month'), eD.endOf('month'))){
      if(r[0] === cl[0])cl[0] = 0
      if(r[0] === cl[1])cl[1] = 0
      r[0] = 0;
    }
    if(!endRange.isBetween(sD.startOf('month'), eD.endOf('month'))){
      if(r[1] === cl[0])cl[0] = 0
      if(r[1] === cl[1])cl[1] = 0
      r[1] = 0;
    }
    if(r[0] === 0 && r[1] > 0){
      r[0] = r[1]
    }
    if(r[1] === 0 && r[0] > 0){
      r[1] = r[0]
    }
    if(cl[0] === 0 && cl[1] > 0){
      cl[0] = cl[1]
    }
    else if(cl[1] === 0 && cl[0] > 0){
      cl[1] = cl[0]
    }
    setLegend({preis: {}, zimmer: {}, online: {}, zusatz: {}})
    setClick(cl)
    setRange(r)
    setApiRange([r[0]===0?tr('bw.cal.calendar.pleasechoose'):moment(r[0]).format('YYYY-MM-DD'), r[1]===0?tr('bw.cal.calendar.pleasechoose'):moment(r[1]).format('YYYY-MM-DD')])
  }
  
  const DisplayLegend = ({calType}) => {
    const lDiv = []
    for(const lk in legend[calType]){
      lDiv.push(
        <div style={{display: 'flex'}}>
          <div className={fixStyles.reactCalendarLegendTile} style={{backgroundColor: legend[calType][lk]}} />
          <div>{data[calType][lk].bezeichnung}</div>
        </div>
      )
    }

    return (
      <div style={{marginBottom: '0.7em'}}>
        <div style={{fontWeight: 'bold', textAlign: 'left'}}>
          {tr('bw.cal.calendar.'+calType)}
        </div>
        <div>
          { calType === 'preis' && <div style={{ display: 'flex' }} >
            <div className={fixStyles.reactCalendarLegendTile} style={{ 
              backgroundColor: 'white', border: '1px solid #000',
              backgroundImage: 'linear-gradient(45deg, rgba(255,255,255,0) 70%, rgba(0,0,0,0.5) 30%)',
              backgroundSize: '1em 1em',
            }} />
            <div>{tr('bw.cal.calendar.mod1')}</div>
          </div>}
          { calType === 'preis' && <div style={{ display: 'flex' }} >
            <div className={fixStyles.reactCalendarLegendTile} style={{ 
              backgroundColor: 'white', border: '1px solid #000',
              backgroundImage: 'linear-gradient(135deg, rgba(255,255,255,0) 70%, rgba(0,0,0,0.5) 30%)',
              backgroundSize: '1em 1em',
            }} />
            <div>{tr('bw.cal.calendar.mod2')}</div>
          </div>}
          <div style={{display: 'flex'}}>
            <div className={fixStyles.reactCalendarLegendTile} style={{backgroundColor: 'white', border: '1px solid #000'}} />
            <div>{tr('bw.cal.calendar.notassigned')}</div>
          </div>
          { lDiv }
        </div>
      </div>
    )
  }
  
  const DateRangePicker = ({calType}) => {
    const [value, setValue] = useState(menuState[calType]);
    if(calType === 'preis'){
      setRDaily(false)
    }

    const formatDate = (date) => {
      return `${date.getFullYear()}${(date.getMonth() + 1).toString().padStart(2, '0')}${date.getDate().toString().padStart(2, '0')}`;
    }
    
    const getDayClass = ({date, view, ...rest}) => {
      const classes = [fixStyles.calCalendarTile]
      if(date >= range[0] && date <= range[1] && weekdays[date.getDay()]){
        classes.push(fixStyles.selectionTile)
      }
      return classes
    }
    const getTileContent = ({activeStartDate, date, view}) => {
      if(date >= range[0] && date <= range[1] && weekdays[date.getDay()]){
        return <div className={fixStyles.selectionWrapper}><span className={fixStyles.selection} /></div>
      }
      return
    }
    const getTileStyle = ({date, view, ...rest}) => {
      const style = {}
      const fdate = formatDate(date)
      if(date.getDay() === 0){
        style.fontWeight = 'bold'
      }
      if(dates && dates[fdate] && dates[fdate]['id_'+calType+'_gr']){
        const gr_id = dates[fdate]['id_'+calType+'_gr']
        if(data && data[calType] && data[calType][gr_id]){
          style.backgroundColor = data[calType][gr_id].id_parent>0?data[calType][data[calType][gr_id].id_parent].farbe:data[calType][gr_id].farbe
          if(calType === 'preis'){
            if(data[calType][gr_id].mod === 1){
              style.backgroundImage = 'linear-gradient(45deg, rgba(255,255,255,0) 75%, rgba(0,0,0,0.5) 25%)'
              style.backgroundSize = '3em 3em'
              setRDaily(true)
            }
            if(data[calType][gr_id].mod === 2){
              style.backgroundImage = 'linear-gradient(135deg, rgba(255,255,255,0) 75%, rgba(0,0,0,0.5) 25%)'
              style.backgroundSize = '3em 3em'
              setRDaily(true)
           }
          }
        }
        if((!legend[calType] || !legend[calType][gr_id]) && data && data[calType] && data[calType][gr_id] && (!data[calType][gr_id].id_parent)){
          const le = legend;
          le[calType][gr_id] = data[calType][gr_id].farbe
          setLegend(le)
        }
      }
      return style
    }
    const getTileTitle = ({date, view, ...rest}) => {
      const fdate = formatDate(date)
      if(dates && dates[fdate] && dates[fdate]['id_'+calType+'_gr']){
        const gr_id = dates[fdate]['id_'+calType+'_gr']
        if(data && data[calType] && data[calType][gr_id]?.bezeichnung){
          return data[calType][gr_id].bezeichnung
        }
      }
      return
    }

    return (
      <div style={{width:'280px', paddingRight:'1em'}}>
        <div style={{fontWeight: 'bold', textAlign: 'center'}}>
          {tr('bw.cal.calendar.'+calType)}
        </div>
        <div>
          <Select
            value={value}
            onChange={(ev) => {const ms = menuState;ms[calType] = ev.target.value;setMenuState(ms);setValue(ev.target.value)}}
            size="small"
            sx={{width: '100%'}}
          >
            { calType === 'preis' && <MenuItem value={-1} disabled={!rDaily}>{tr('bw.cal.calendar.resetdaily')}</MenuItem>}
            { calType !== 'zimmer' && <MenuItem value={0}>{tr('bw.cal.calendar.nochange')}</MenuItem>}
            { menuItems[calType] }
          </Select>
        </div>
        <Calendar 
          activeStartDate={new Date(startDate.format('YYYY-MM-DD')+' 12:00:00')}
          onChange={handleSetRange} 
          value={range} 
          className={fixStyles.reactCalendar}
          tileClassName={getDayClass}
          tileContent={getTileContent}
          tileStyle={getTileStyle}
          tileTitle={getTileTitle}
          selectRange
          allowPartialRange
          showDoubleView={months}
          showFixedNumberOfWeeks={false}
          showNeighboringMonth={false}
          showNavigation={false}
          />
      </div>
    );
  };
  
  return (         
    <Paper style={{scroll:'auto'}} >
      <CardContent>
      <div style={{display:'flex', justifyContent: 'space-between', paddingBottom: '1em'}}>
        <div>
          <LocalizationProvider dateAdapter={AdapterMoment} >
            <Button variant="contained" color="primary" value="-" onClick={()=>handleShiftMonth(-1)} style={{marginRight:'1em'}}> &lt; </Button>
            <DatePicker sx={{mt: 0, mb: 0, width: '13em',
              '& .MuiInputBase-root': { height: '40px', padding: '0 14px' },
              '& .MuiInput-underline:before': { borderBottom: 'none' },
              '& .MuiInput-underline:after': { borderBottom: 'none' },
              '& .MuiInput-underline': { '&:hover:not(.Mui-disabled):before': { borderBottom: 'none' }},
            }}
              label={tr('bw.cal.calendar.monthfrom')}
              views={['month','year']}
              value={startDate}
              onChange={(ev) => {handleSetStartDate(ev)}}
            />
            <DatePicker sx={{mt: 0, mb: 0, ml: '1em', width: '13em',
              '& .MuiInputBase-root': { height: '40px', padding: '0 14px' },
              '& .MuiInput-underline:before': { borderBottom: 'none' },
              '& .MuiInput-underline:after': { borderBottom: 'none' },
              '& .MuiInput-underline': { '&:hover:not(.Mui-disabled):before': { borderBottom: 'none' }},
            }}
              label={tr('bw.cal.calendar.monthto')}
              views={['month','year']}
              value={endDate}
              onChange={(ev) => {handleSetEndDate(ev)}}
            />
            <Button variant="contained" color="primary" value="-" onClick={()=>handleShiftMonth(1)} style={{marginLeft:'1em'}}> &gt; </Button>
          </LocalizationProvider>
          <Button variant="contained" color="primary" value="2 Months" onClick={()=>{handleSetMonths(2)}} style={{marginLeft:'1em'}}>{"2 "+tr('bw.cal.calendar.months')}</Button>
          <Button variant="contained" color="primary" value="3 Months" onClick={()=>{handleSetMonths(3)}} style={{marginLeft:'1em'}}>{"3 "+tr('bw.cal.calendar.months')}</Button>
          <Button variant="contained" color="primary" value="6 Months" onClick={()=>{handleSetMonths(6)}} style={{marginLeft:'1em'}}>{"6 "+tr('bw.cal.calendar.months')}</Button>
          <Button variant="contained" color="primary" value="12 Months" onClick={()=>{handleSetMonths(12)}} style={{marginLeft:'1em'}}>{"12 "+tr('bw.cal.calendar.months')}</Button>
        { isHauptadmin() && <div style={{ marginLeft: '.8em', display: 'inline'}}><Checkbox checked={showRooms} onChange={(ev)=>{ setShowRooms(ev.target.checked) }} />{tr('bw.cal.calendar.zimmer')}</div> }
        </div>
      </div>
      <div style={{display:'flex', justifyContent: 'flex-start', paddingBottom: '1em'}}>
        <div style={{ lineHeight:'2.5em'}}>{tr('bw.cal.calendar.startdate')}: <b>{apiRange[0]}</b></div>
        <div style={{ marginLeft:'1em', lineHeight:'2.5em'}}>{tr('bw.cal.calendar.enddate')}: <b>{apiRange[1]}</b></div>
        { undoIdx >0 && <Button variant="contained" color="primary" value="assign" onClick={()=>{undo()}} style={{marginLeft:'1em'}}>{tr('bw.cal.calendar.undo')+undoIdx}</Button> }
        <Button variant="contained" color="primary" value="assign" onClick={()=>{assignDates()}} disabled={click[0]===0 || click[1]===0} style={{marginLeft:'1em'}}>{tr('bw.cal.calendar.assign')}</Button>
        <div style={{ marginLeft:'2em', lineHeight:'2.5em'}}>{tr('bw.cal.calendar.weekdays')}:</div>
        <div><Checkbox checked={weekdays[1]} onChange={(ev)=>{ const wd = [...weekdays]; wd[1] = ev.target.checked; setWeekdays(wd); }} />{tr('glob.day.short.mon')}</div>
        <div><Checkbox checked={weekdays[2]} onChange={(ev)=>{ const wd = [...weekdays]; wd[2] = ev.target.checked; setWeekdays(wd); }} />{tr('glob.day.short.tue')}</div>
        <div><Checkbox checked={weekdays[3]} onChange={(ev)=>{ const wd = [...weekdays]; wd[3] = ev.target.checked; setWeekdays(wd); }} />{tr('glob.day.short.wed')}</div>
        <div><Checkbox checked={weekdays[4]} onChange={(ev)=>{ const wd = [...weekdays]; wd[4] = ev.target.checked; setWeekdays(wd); }} />{tr('glob.day.short.thu')}</div>
        <div><Checkbox checked={weekdays[5]} onChange={(ev)=>{ const wd = [...weekdays]; wd[5] = ev.target.checked; setWeekdays(wd); }} />{tr('glob.day.short.fri')}</div>
        <div><Checkbox checked={weekdays[6]} onChange={(ev)=>{ const wd = [...weekdays]; wd[6] = ev.target.checked; setWeekdays(wd); }} />{tr('glob.day.short.sat')}</div>
        <div><Checkbox checked={weekdays[0]} onChange={(ev)=>{ const wd = [...weekdays]; wd[0] = ev.target.checked; setWeekdays(wd); }} />{tr('glob.day.short.sun')}</div>
      </div>
      <div style={{display:'flex', justifyContent: 'flex-start'}}>
        <DateRangePicker calType="preis" />
        { showRooms && <DateRangePicker calType="zimmer" /> }
        <DateRangePicker calType="online" />
        <DateRangePicker calType="zusatz" />
        <div>
          <DisplayLegend calType="preis" />
          { showRooms && <DisplayLegend calType="zimmer" /> }
          <DisplayLegend calType="online" />
          <DisplayLegend calType="zusatz" />
        </div>
      </div>
      <Dialog onClose={()=>setError(false)} open={error}>
        <DialogTitle>Warning/Error</DialogTitle>
        <DialogContent>
          <div>{error && error.length>0 && error.map((v)=> (<div>{v}</div>) )}</div>
        </DialogContent>
      </Dialog>
      </CardContent>
    </Paper>         
  )
}
export default CalCalendar;