import React, { useState, useEffect } from 'react';
import { Toolbar, Card, CardContent, Stack, Menu, MenuItem, ListItemIcon, ListItemText, Typography,
  Drawer, DialogTitle, DialogContent, DialogContentText, DialogActions,
  Button, TextField, 
 } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslate } from 'react-admin';

import dataProvider from '../../lib/dataProvider';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

import moment from 'moment';
import 'moment/dist/locale/de';


const AppDash = ({permissions}) => {
  const translate = useTranslate();  
  const [start, setStart] = useState(moment().format('YYYYMMDD'));
  const [rowList, setRowList] = useState([]);
  const [gridData, setGridData] = useState({});
  const [showOpen, setShowOpen] = useState(false);
  const [resInfo, setResInfo] = useState({});
  const [hostelsetting, setHostelsetting] = useState({});
  const [view, setView] = useState(false);
  const [viewSettings, setViewSettings] = useState({"AnzahlNaechte": 7,
            "AnzBetten": 20,
            "ColorSet": 0,
            "ShowKat": "",
            "StartOffset": 1,
            "ShowBedInfo": false,
            "ShowInfos": true,
            "ShowMatratzen": false,
            "UseRoomColor": true,
            "VisibDays": 3,
            "Viewname": "",
            "Zimmerlist": false});
            
  useEffect(() => {
    dataProvider.getList('appRow', {
        filter: { ShowKat: viewSettings.ShowKat, Zimmerlist: viewSettings.Zimmerlist, ShowMatratzen: viewSettings.ShowMatratzen  },
        sort: false,
        pagination: { page: 1, perPage: 20 },
    })
      .then(response => {
        if (response.data) {
          setRowList(response.data);
        }
      });
  }, [viewSettings]);

  useEffect(() => {
    dataProvider.getList('appHostelsetting', { sort: false,pagination: { page: 1, perPage: 200 } })
      .then(response => {
        const hs = {};
        response.data.forEach((r)=>{
          hs[r.id] = r.value;
        })
        setHostelsetting(hs);
        if (view===false) {
          setView('view1');
          setViewSettings(hs['view1']);
        }
      });
  }, []);

  useEffect(() => {
    dataProvider.getList('appGrid', {
        filter: { start, duration: viewSettings.AnzahlNaechte },
        sort: false,
        pagination: { page: 1, perPage: 20 },
    })
      .then(response => {
        if (response.data) {
          response.data.forEach((r)=>{
            setGridData((gd)=>{ return {...gd, [r.bett_id]:r.bel}});
          })
        }
      });
  }, [viewSettings, start]);

  useEffect(() => {
    if (!showOpen) return;
    dataProvider.getOne('appReservation', { id: showOpen.id})
      .then(response => {
        if (response.data) {
          setResInfo(response.data);
        }
      });
  }, [showOpen]);

  const colorStyle = (col) => {
    const res = {backgroundColor:'#'+col.toString(16).padStart(6, '0')}
    var hell = 0.299*Math.floor(col / 256 / 256)+0.587*(Math.floor(col / 256) % 256)+0.114*(col % 256);
    res.color = hell < 127?'#FFF':'#000';
    return res;
  }
  const dropHandler = (ev) => {
    ev.preventDefault();
    console.log(ev);
  }

  const cellClick = (ev) => {
    console.log(ev);
    const id = ev.target.id.split('_');
    if (id[2]) {
      setShowOpen({id: id[2]});
    }
  }

  const viewChange = (ev) => {
    setView(ev.target.value);
    setViewSettings(hostelsetting[ev.target.value]);
  }

  const nav = (offset) => (ev) => {
    if (offset===0) {
      setStart(moment().add(-1, 'days').format('YYYYMMDD'));  
    }
    else {
      setStart(moment(start).add(offset, 'days').format('YYYYMMDD'));
    }
  }

  const dragoverHandler = (ev) => {
    ev.preventDefault();
    console.log(ev);
    return true;
  }

  if (rowList.length < 1) return null;
  let lastMonth = -1;
  let offen = resInfo.netto;
  return (  
    <>
    <Card >
      <CardContent sx={{paddingTop:0}}>
        <toolbar>
        <Button onClick={nav(-viewSettings.AnzahlNaechte)}><KeyboardDoubleArrowLeftIcon /></Button>
        <Button onClick={nav(-1)}><KeyboardArrowLeftIcon /></Button>
        <Button onClick={nav(0)}>Heute</Button>
        <Button onClick={nav(1)}><KeyboardArrowRightIcon /></Button>
        <Button onClick={nav(viewSettings.AnzahlNaechte)}><KeyboardDoubleArrowRightIcon /></Button>

        <select value={view} onChange={viewChange} >
  {[...Array(18)].map((el, k) => {
    const key = 'view' + k;
    if (!hostelsetting[key]) return null;
    const viewname = hostelsetting[key].Viewname;
    if (!viewname)  return null;
    return (
          <option key={key} value={key}>
            {viewname}
          </option>
    )
  }
  )}     
        </select>

        </toolbar>
        <table className="appgrid">
          <thead>
            <tr>
              <th className={viewSettings.ShowBedInfo?'ShowBedInfo':''}>{moment(start).format('YYYY')}</th>
    {[...Array(viewSettings.AnzahlNaechte)].map((el, k) => {
              var date = moment(start).add(k, 'days');
              var first = date.format('M')!==lastMonth;
              lastMonth = date.format('M');
              return (
                <th key={k} style={{fontSize:"0.8125rem"}}>
                  {first?date.format('MMM'):''}<br/>
                  <span style={{backgrounddColor:date.day()%6===0?'#ccc':'#FFF'}}>{date.format('ddd D')}</span>
                </th>
      )}            
    )}            
              </tr>
            </thead>
          <tbody>
    {rowList.map((bett, k) => {
//      if (bett.bett != '06A') return null;
      const row = [];
      let current = {};
      let days = 0;
      const bel = gridData[bett.bett_id]?gridData[bett.bett_id]:[];
      for (let i = 0; i < viewSettings.AnzahlNaechte; i++) {
        const d = moment(start).add(i, 'days').format('YYYYMMDD');
        if (bel[d]) {
          i += bel[d].dauer - 1;
          row.push(bel[d]);
        }
        else {
          row.push({name:'', dauer:1, reservation_id:0});
        }
      }
      return (
        <tr key={k}>
          <td style={colorStyle(bett.farbe)}>{bett.bezeichnung} {bett.buchstabe} {viewSettings.ShowBedInfo?bett.bemerkung:''}</td>
          {row.map((cell, k) => {
            const d = moment(start).add(k, 'days').format('YYYYMMDD');
            const id = bett.bett_id + '_' + d + '_' + cell.reservation_id;
            return (
              <td key={id} id={id} colSpan={cell.dauer} className={"res"+cell.id_agent} onClick={cellClick} draggable="true" onDrop={dropHandler} onDragOver={dragoverHandler}>{cell.res_nr} {cell.name}</td>
            )
          })}
        </tr>
      )
    })}
          </tbody>
        </table>
      </CardContent>
    </Card>
    <Drawer anchor="right" open={showOpen!==false && resInfo} onClose={()=>{setShowOpen(false)}} >
      <Toolbar sx={{backgroundColor:"#eee"}}><Button variant="contained" onClick={()=>{setShowOpen(false)}}>Cancel</Button></Toolbar>
      <DialogContent>
        <Stack className="textdisp" direction="row" spacing={2} style={{alignItems: "flex-end"}}>
          <span style={{fontSize:'1.5em'}}>{resInfo.gastname}</span>
          <span>{resInfo.res_nr}</span>
        </Stack>
        <Stack className="textdisp" direction="row" spacing={2} style={{alignItems: "flex-end", flexWrap: 'wrap' }}>
          <span>{moment(String(resInfo.ankunft)).format('dd MMMM Do YYYY')}</span>
          <span>{resInfo.dauer} Nights</span> 
          <span>{moment(String(resInfo.ankunft)).add(resInfo.dauer, 'days').format('MMMM Do YYYY')}</span>
        </Stack>
        <Stack className="textdisp" direction="row" spacing={2} style={{alignItems: "flex-end", flexWrap: 'wrap' }}>
          <span>State: Normal</span>
          <span>Agent: {resInfo.agentname}</span>
          <span>Not important</span> 
          <span>Internet</span>
          <span>kein Reminder</span>
        </Stack>
        <div className="textdisp">
          <table style={{width:"100%"}}>
            <tr>
              <td colSpan={3}>Preis</td>
              <td className="right">{resInfo.netto && resInfo.netto.toFixed(2)}</td>
            </tr>
  {resInfo.zahlung && resInfo.zahlung.length > 0 && resInfo.zahlung.map((z) => {
      offen -= z.betrag;
      return (
            <tr key={z.zahlung_id}>
              <td>{moment.unix(z.datum).format('DD.MM.YYYY HH:mm')}</td>
              <td>{z.text}</td>
              <td>{z.bezeichnung}</td>
              <td className="right">{z.betrag.toFixed(2)}</td>
            </tr>
      )
  })}          
            <tr>
              <td colSpan={3}>Offen</td>
              <td className="right">{offen && offen.toFixed(2)}</td>
            </tr>
          </table>
        </div>
        <div className="textdisp">{resInfo.bemerkung}</div>
        <div className="textdisp">
          <table style={{width:"100%"}}>
  {resInfo.betten && resInfo.betten.length > 0 && resInfo.betten.map((z) => {
      return (
            <tr key={z.person_id}>
              <td>{z.bezeichnung}{z.buchstabe}</td>
              <td>{z.geschlecht}</td>
              <td>{z.alterskat}</td>
              <td>{z.pkbez}</td>
              <td>{z.preis}</td>
              <td>{z.vorname} {z.name} {z.wohn}-{z.pass}</td>
            </tr>
      )
  })}          
          </table>
        </div>
      </DialogContent>
    </Drawer>
    </>
  );
}
export default AppDash;
