const hn = window.location.hostname
var isTest = true;
var apiPath = '';
var oldDomain = '';
var mapApi = '';
var testsystemId = '';
switch(hn) {
  case 'admin.book.world':
  case 'admin-m.book.world':
  case 'admin-s.book.world':
    isTest = false;
    apiPath = 'https://'+hn.replace('admin', 'restapi')+'/'; 
    oldDomain = 'https://wrap.bookdotworld.com'
    //oldDomain = 'https://'+window.location.hostname.replace('admin', 'wrap').replace('.book.world', '.alixon.ch');
    mapApi = 'AIzaSyC50OiKWQUCgtyYcvTfreMst17wn-vHpQ0'
    break;
  
  case 'localhost':
    const systno = window.location.port - 3000;
    apiPath = 'https://restapi.dev'+systno+'.book.world/';
    oldDomain = 'https://dormdev'+systno+'.alixon.ch'
    mapApi = 'AIzaSyAqxA-cpTjwJkNIqxEQzFTL5AI5XZ-5L5U'
    testsystemId = 'dev'+systno
    break;
  
  default:    // admin.dev1.book.world
    apiPath = 'https://'+hn.replace('admin.', 'restapi.')+'/'; 
    oldDomain = 'https://'+hn.replace('admin.', 'dorm').replace('.book.world', '.alixon.ch');  //dormdev1.alixon.ch
    mapApi = 'AIzaSyAqxA-cpTjwJkNIqxEQzFTL5AI5XZ-5L5U'
    testsystemId = hn.split('.')[1];
  break;
  
}

export { apiPath, oldDomain, mapApi, testsystemId };

export default isTest;
