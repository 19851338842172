import React from 'react';
import { getGlobalData, isHauptadmin } from '../../lib/globalData';
import { useTranslate, useRecordContext, List, Datagrid, TextField, DateField, 
  TextInput, Filter } from 'react-admin';
import NoPagination from '../../lib/NoPagination';
import { apiPath } from '../../lib/isTest';

const ShowDocument = (props) => {
  const translate = useTranslate();
  const record = useRecordContext();

  var token = getGlobalData('token');
  return (<a href={apiPath+'v1/repDocuments/'+record.id+'?action=show&token='+token} target="_blank" rel="noreferrer">{translate('bw.rep.documents.show')}</a>)
}

const DownloadDocument = (props) => {
  const translate = useTranslate();
  const record = useRecordContext();
  var token = getGlobalData('token');
  return (<a href={apiPath+'v1/repDocuments/'+record.id+'?token='+token} target="_blank" rel="noreferrer">{translate('bw.rep.documents.download')}</a>)
}

const ListForm = (props) => {
  const {classes, ...rest} = props;
  return (
    <List {...rest} sort={{ field: 'datum', order: 'DESC' }} 
      exporter={isHauptadmin() && props.exporter} pagination={<NoPagination />} perPage={1000} >
      <Datagrid style={{ width: 'auto' }} bulkActionButtons={false} >
        <DateField source="datum" showTime options={{ timeZoneName:'shortOffset' }} />
        <TextField source="type" />
        <TextField source="flag" />
        <TextField source="titel" />
        <TextField source="filename" />
{isHauptadmin() &&
        <TextField source="id" textAlign="right" />
}
        <DownloadDocument {...rest} />
        <ShowDocument {...rest} />
      </Datagrid>
    </List>
  )
}

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput source="titel" alwaysOn />
        <TextInput source="filename" alwaysOn />
        <TextInput source="flag" alwaysOn />
    </Filter>
);

const Documents =  {
  list: ({ classes, ...props }) => (
    <ListForm {...props} classes={classes} filters={<ListFilter />} />
  ),
  
}   
export default Documents